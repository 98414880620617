<template>
  <div
    class="x-to-live-page"
    :style="setBackground"
  >
    <zoom-center-transition
      :duration="pageTransitionDuration"
      mode="out-in"
    >
      <div class="login-content">
        <material-card
          class="v-card-profile"
          :width="270"
        >
          <img
            class="my-logo mt-2"
            :src="image"
          >
          <v-card-text class="text-xs-center">
            <h4
              class="card-title font-weight-light mt-1 mb-3 success--text"
            >
              Test
            </h4>
            <h4 class="card-title font-weight-light mt-1 mb-3 error--text">Notice</h4>
            <p class="card-description font-weight-light mb-3">This website is for test only!</p>
            <p class="card-description card-description-more font-weight-light mb-4">To work at live projects, Please click button below.</p>

            <v-btn
              color="primary"
              rounded
              class="font-weight-light ma-3"
              @click="goLive"
              dark
            >
              Go To Live 
              <span style="margin-left: 9px;">></span>
            </v-btn>
          </v-card-text>
        </material-card>

        <copyright-component text-color="white" />
      </div>
    </zoom-center-transition>
  </div>
</template>

<script>
import { ZoomCenterTransition } from "vue2-transitions"
import { isProductEnv } from '@/config'
import CopyrightComponent from './copyright/copyright.vue'

export default {
  name: 'ToLive',
  components: {
    ZoomCenterTransition,
    CopyrightComponent,
  },
  data() {
    return {
      loading: false,
      pageTransitionDuration: 300,
      image: require('@/static/img/icons/login.png'),

      isProductEnv: isProductEnv,
    }
  },
  computed: {
    setBackground() {
      return {
        'background-image': `url(${require('@/static/img/app/bg/login.jpg')})`
      };
    }
  },
  methods: {
    goLive () {
      window.location.href = 'https://ginkgo.nielseniq.cn'
    }
  },
  beforeCreate () {
    if (isProductEnv) this.$router.push('/')
  }
}
</script>

<style lang="sass">
.x-to-live-page
  height: 100%
  min-height: 100vh
  background-position: top
  background-size: cover
  margin: 0
  padding: 0 0 0 100px
  border: 0
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  justify-content: center

  &:before
    position: absolute
    width: 100%
    height: 100%
    display: block
    left: 0
    top: 0
    content: ""
    background-color: rgba(0, 0, 0, .5)
  

  .v-card-profile
    margin-right: 100px
    text-align: center  

  .login-content
    .my-logo
      width: 110px
      -webkit-box-shadow: none !important
      box-shadow: none !important
    

    .copyright
      position: fixed
      left: 0
      bottom: 0
      text-align: center !important
      width: 100%
      color: rgba(255,255,255,.9)
      font-size: 12px
    
  

  .card-description-more
    text-align: left
  

</style>
